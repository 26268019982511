@media (max-width: 1536px) {
  .hovermenu-container ul.notificationContainer.hovermenu {
    left: -1000%;
  }
  .dashboard__body {
    padding: 0 80px 0 15px;
  }
}
@media (max-width: 1440px) {
  .dashboard__body {
    padding: 0 20px 0 10px;
  }
  .downloadWrapper {
    padding-right: 55px;
  }
  .dashboard__navbar > div > ul > li {
    font-size: 14px;
  }
  /* .boughtSet {
    display: none;
  } */

  .ubtSetQuestions .text-end {
    width: 100%;
  }
  .ubtSetQuestions .btn,
  .normalsetsitem .btn {
    /* width: 100%; */
    padding: 10px;
  }
}

@media (max-width: 1240px) {
  .test-percentage span {
    font-size: 16px;
    font-weight: 550;
  }

  .test-percentage p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 550;
  }
  .dashboards-allcontent .col-md-8 {
    width: 60%;
  }

  .dashboards-allcontent .col-md-4 {
    width: 40%;
  }
}

@media (max-width: 992px) {
  .singleblog-container {
    padding: 20px 0;
  }
  .dashboardUser__mobile {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-direction: row-reverse;
  }
  .notificationContainer {
    width: 320px;
  }
  .eachnotification .notificationimg {
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    object-fit: contain;
    border-radius: 100%;
  }
  .dashboards-allcontentwrapper {
    padding: 0;
  }
  .dashboard-results__wrapper .close {
    right: 10px;
    top: 10px;
  }

  .dashboard__body {
    padding: 15px;
    height: auto;
  }
  .notverified-container h2 {
    font-size: 20px;
    margin-top: 15px;
  }
  .burger--menu {
    display: block;
  }
  .menuProfile {
    display: inherit;
  }

  .hovermenu-container {
    vertical-align: middle;
  }
  .dashboard__navbar .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dashboard__navbar.heightfull {
    height: auto;
  }

  .dashboard__right {
    display: none;
  }
  .dashboard__navbar {
    height: auto;
    position: relative;
  }

  .dashboard__navbar--items,
  .quick-links {
    display: none;
  }

  .dashboards-allcontent {
    padding: 15px;
    border-radius: 12px;
  }

  .burger__menu {
    display: inline-block;
  }
  .site__header.absolute {
    position: static;
  }

  .site__header.sticky-top {
    position: sticky;
  }
  .logo img {
    width: 150px;
  }
  .banner--img .mainimg {
    margin: 0;
  }
  .slick-prev {
    left: 40% !important;
  }
  .slick-next {
    left: 50%;
  }
  .downloadBtn {
    margin-bottom: 15px;
  }

  .downloadBtn > a img {
    width: 150px;
  }

  .banner--text h1 {
    font-size: 35px;
    margin-top: 8px;
    width: 50%;
  }

  .banner--text {
    top: 40%;
  }

  .banner--text p {
    display: none;
  }

  .section-head h2 {
    font-size: 30px;
    line-height: 1.3;
  }

  .about--content p {
    width: 100%;
    font-size: 16px;
  }

  .section-head {
    margin-bottom: 25px;
  }

  .section-head p {
    font-size: 16px;
  }

  /* .svg__banner svg {
    width: 100%;
    height: 100%;
  } */
  .svg__banner svg {
    width: 1020px;
  }

  .grammar--topics .btn {
    padding: 8px 24px;
    white-space: nowrap;
  }
  .secondrow-pad {
    padding-right: 0;
  }
}

@media (max-width: 850px) {
  .addCommentBlog {
    padding: 15px;
    border-radius: 5px;
  }
  .latestblog {
    padding-left: 0;
    margin-left: 0;
    border-left: 0;
  }
  .miniblog-container img {
    max-height: 130px;
  }
  .dashboard-page-title.greetinguser {
    font-size: 22px;
  }
  .dashboards-allcontent .col-md-8 {
    width: 100%;
  }

  .dashboards-allcontent .col-md-4,
  .home__dictionary .col-md-4 {
    width: 100%;
    order: -1;
    margin-bottom: 25px;
  }

  .cta--btns {
    margin-left: auto;
  }
  .navbar {
    display: none;
  }

  .page-wrap h3 {
    font-size: 25px;
  }

  .page-wrap span {
    font-size: 16px;
  }

  .services-wrap .img img {
    width: 45px;
  }

  .services-wrap .img {
    min-width: 60px;
    height: 60px;
  }
  .latestblog {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .blog-container{
    flex-direction: column-reverse;
  }
  .ubtSetQuestions .sets-content > div {
    flex: 3;
  }
  .btn-small {
    padding: 10px;
    font-size: 10px;
  }
  .modal-body .btn-small {
    font-size: 12px;
    padding: 10px 25px;
  }
  .ubt__boughtSet .setstitem-container {
    flex-direction: column;
    gap: 15px;
  }

  .ubtSetQuestions .btn {
    width: auto;
  }
  .ubtSetQuestions {
    gap: 15px;
  }
  .ubtSetQuestions .newTest {
    margin-top: 4px;
  }
  .setsitem-container .pdficon img,
  .normalsetsitem .pdficon img {
    margin-right: 15px;
  }
  .sets-content h5 {
    font-size: 16px;
  }
  .ubtSetQuestions .btn {
    padding: 10px;
    font-size: 10px;
  }
  .sets-content h3 {
    font-size: 16px;
  }
  .setstitem-container p,
  .setstitem-container p span,
  .setstitem-container p del {
    font-size: 14px;
  }
  .purchasedSets .progressbar-container {
    display: none;
  }
  .cta__btns > a {
    margin-left: 9px;
  }

  .image-container.audioImg + .mcqAnswers p img.audioPlayClass {
    width: 50px;
  }

  .commentSorting {
    display: block;
  }

  .commentSorting form {
    margin-bottom: 15px;
  }

  .commentSorting form select {
    width: 100%;
  }

  .resultUpdate {
    margin: 0;
  }
  .grammar__section {
    padding: 15px;
  }
  .person-image {
    width: 100%;
    display: flex;
  }
  .userCommentDetail {
    width: 100%;
    margin: 0;
    margin-top: 15px;
  }

  .userProfile {
    padding-left: 0;
  }
  .person-image img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
  .userProfile strong {
    margin-left: 0;
  }

  .person-image > div {
    display: block;
  }

  .userCommentAll {
    margin: 0;
    margin-top: 15px;
  }

  .person-image p,
  .person-image strong {
    font-size: 14px;
    line-height: 100%;
  }

  .viewAllLink {
    display: block;
  }

  .boughtSet .setstitem-container {
    display: block;
  }
  .notverified-container button {
    margin-top: 15px;
  }
  .eachdashboard-detail p,
  .packageName p {
    font-size: 14px;
  }
  .modal-dialog p {
    font-size: 16px;
  }
  .banner--text span {
    font-size: 16px;
  }
  .rightsidebar {
    margin-top: 15px;
  }

  .commentDetails > h5 {
    display: none;
  }
  .comment-details {
    padding: 15px;
  }

  .person-image h5 {
    font-size: 15px;
    color: var(--secondary--color);
  }

  .comment-details .commentDetails {
    margin: 0;
    margin-top: 15px;
  }

  .site__header.sticky-top .logo img {
    width: 115px;
  }
  .mcqAnswers {
    display: block;
  }
  .about--content {
    padding: 0;
    margin-top: 50px;
  }
  .download-content p {
    font-size: 16px;
  }
  .downloadBtn img {
    width: 150px;
  }
  .banner--text {
    top: 50%;
  }
  .banner--text h1 {
    width: 75%;
  }

  .bannerServices .eachservicecard {
    margin-bottom: 25px;
  }

  .bannerServices {
    margin: 0;
  }
  .section-head h2 br {
    display: none;
  }
  .section-head > div {
    display: block;
  }
  .section-head > div h2 {
    margin-bottom: 8px;
  }
  .logo img {
    width: 115px;
  }
  .mainblog-container {
    margin-bottom: 10px;
  }
  .footer-section .footer-korean {
    bottom: 106px;
    right: 45px;
    left: auto;
  }
  .bottomFooter ul {
    justify-content: center;
  }
  .bottomFooter .copyrights {
    text-align: center;
    margin-top: 5px;
  }

  .banner--img .mainimg img {
    height: 250px;
    object-fit: cover;
    object-position: right;
  }

  .banner--text {
    text-align: center;
    position: static;
    transform: none;
    padding: 15px 0;
    background: #eee;
  }

  .downloadWrapper {
    display: none;
  }

  .footer-section {
    padding-top: 50px;
  }

  .footerlogo {
    text-align: center;
    margin-bottom: 15px;
  }
  .ubt__boughtSet .setstitem-container > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .bigreclaim {
    display: block;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 1200px;
  }
  .container-sm {
    max-width: 1000px;
  }
}
@media (max-width: 640px) {
  .mainblog-container.twocolumn > a{
    width: 100%;
  }
  .mainblog-content {
    margin-top: 10px;
  }
  .allcategory-container li.active {
    background-color: var(--secondary--color);
  }
  .allcategory-container li.active div {
    color: #fff;
  }
  .allcategory-container li div::after,
  .allcategory-container li.active div::after {
    content: "";
  }
  .mainblog-container h3 {
    margin-bottom: 7px;
  }
  .mainblog-container h3 a {
    font-size: 17px;
    color: #333;
  }
  .BlogDate {
    margin: 5px 0;
  }
  .mainblog-content .anchor-text {
    margin: 0;
  }
  .allcategory-container {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    margin-bottom: 15px;
  }
  .allcategory-container li {
    padding: 0 25px;
    border-radius: 5px;
  }
  .mainblog-container.twocolumn {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .allcategory-container li div {
    white-space: nowrap;
    border-bottom: 0;
    padding: 7px 0;
    border-radius: 5px;
    color: #333;
  }
  .singleblog-container h3 {
    font-size: 22px;
  }
  .grammar-container {
    overflow: hidden;
  }
  .grammar-container .red-bg-sidebar {
    padding: 15px;
  }
  .grammar--share i {
    font-size: 20px;
  }
  .dashboard-page-title > a {
    margin: 0;
    margin-top: 15px;
    padding: 7px 15px;
    line-height: 0;
    background-color: #fff;
    border-radius: 8px;
    display: block;
    width: fit-content;
    font-size: 24px;
  }

  .grammar--details .dashboard-page-title {
    font-size: 21px;
  }
  .grammar--share {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .grammar-listeach {
    padding: 15px !important;
  }

  .testQuestions .cta__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .testQuestions .cta__btns .btn {
    padding: 7px 15px;
  }
  .questions {
    padding: 25px 15px;
  }
  .questionOption {
    width: 100%;
  }
  .questionGroups > span {
    margin: 0;
    position: absolute;
  }
  .questionGroups > div {
    margin-left: 0;
    padding-top: 35px;
  }
  .btn {
    padding: 12px 20px;
  }
  .section-head h2 {
    font-size: 26px;
  }
  .about-banner .img-2 {
    display: none;
  }
  .grammar--topics {
    flex-wrap: nowrap;
    overflow: auto;
  }
  .grammar__section {
    padding: 0;
    border: 0;
    margin-top: 15px;
  }
  .myDiscussion ul {
    margin-bottom: 10px;
  }
  .comment__section > div {
    padding: 20px 15px;
  }
  .grammar--details > div {
    padding: 10px;
  }
  .singleselected {
    margin-top: 0;
  }
  .grammarDetails .d-flex {
    flex-direction: column;
  }
  .grammarDetails h4 {
    margin: 0;
    margin-bottom: 10px;
  }
  .singleselected > div > div {
    position: relative;
    flex-direction: column;
    justify-content: start !important;
    align-items: flex-start !important;
  }
  .postBlog {
    gap: 10px;
  }
  .package-bottom {
    margin-top: 35px;
    padding-top: 25px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .test-percentage p {
    font-size: 20px;
    font-weight: 700;
  }
  .test-percentage span {
    font-size: 14px;
    color: #777;
    line-height: 1.25;
  }
  .prog {
    width: 0%;
  }
  .dashboard-progress .testscore {
    margin: 0;
    width: 50%;
  }
  .testscore svg {
    left: 50%;
    transform: translateX(-50%);
  }
  .progressbar-container {
    padding: 12px 20px;
    margin: 10px 0;
  }
  .dashboard-progress:not(.dashboard-results-progress) {
    flex-wrap: nowrap;
  }
  .testscore svg {
    width: 140px;
  }
  .prog > div > div > div {
    position: absolute;
    left: 26%;
    margin-top: 0 !important;
    top: 46%;
    transform: translate(-50%, -50%);
  }
  .test-percentage p {
    margin-bottom: 0;
  }
  .ubtSetQuestions {
    flex-direction: column;
  }
  .dashboard-results__wrapper .close {
    top: -50px;
  }
  .showonmobile {
    display: block;
  }
  .hideonmobile {
    display: none;
  }
  .dashboard-contents {
    padding: 15px 10px;
  }
  .subPackages ol {
    max-height: 350px;
    overflow: auto;
  }
  /* .setsitem-header {
    justify-content: center;
    flex-direction: column;
  }
  .pdficon {
    flex-direction: column;
  } */
  /* .sets-content {
    padding: 0;
    margin: 0;
    margin-top: 10px;
    border-left: 0;
  } */
  /* .sets-content h3 {
    font-size: 18px;
    text-align: center;
  } */

  .setsitem-container {
    padding: 20px 15px;
  }

  .sets-content .text-end {
    width: auto;
  }

  .sets-content h3 {
    font-size: 16px;
  }
  .dashboards-allcontent .col-md-4 .setsitem-header {
    justify-content: space-between;
  }
  .setsitem-container {
    height: 100%;
  }
  .banner--text {
    text-align: center;
  }

  .banner--text h1 {
    font-size: 30px;
    width: 100%;
  }
  .socialmedia-login {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .modalbody {
    width: 95%;
    padding: 45px 35px;
  }
  .eachlogin-btn {
    display: inline-block;
  }
  .eachlogin-btn span {
    display: none;
  }
  .eachlogin-btn img {
    margin-right: 0;
  }
  .eachlogin-btn:last-child {
    margin-bottom: 13px;
  }
}
@media (max-width: 580px) {
  .mainblog-container > a {
    max-height: 245px;
  }
  .dashboard-section {
    padding-top: 25px;
  }
  .dashboards-allcontent .col-md-4 {
    margin-bottom: 0;
  }
  .buyPackages {
    margin-bottom: 15px;
  }
  .subPackages.boughtpackages ol {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  /* .subPackages.boughtpackages ol li {
    flex-direction: column;
  }
  .subPackages.boughtpackages ol li .btn {
    margin-top: 10px;
  } */
}

@media (max-width: 468px) {
  .dashboard-results-progress {
    width: 100%;
  }
  .packageTime {
    top: -30px;
  }
  .slick-prev {
    left: 34% !important;
  }

  .cta--btns {
    text-align: center;
  }

  .cta--btns .btn {
    padding: 7px 15px;
    font-size: 12px;
  }
}

@media (max-width: 430px) {
  .setstitem-container > div .text-end {
    margin-left: 5px;
    flex: 2;
  }
  .testscore svg {
    width: 120px;
  }
}

@media (max-width: 375px) {
  .questionGroups > div {
    margin-left: 10px;
  }
  .questionOption p {
    padding-left: 55px;
  }
  .hovermenu-container ul.notificationContainer.hovermenu {
    left: -90 0%;
  }
  .setsitem-container .pdficon img {
    width: 50px;
  }
  .ubtSetQuestions .btn {
    width: 100%;
  }
}
